import { Controller } from "@hotwired/stimulus"

declare global {
    interface Element {
        controllers?: { [key: string]: ApplicationController }
    }
}

export default class ApplicationController extends Controller {
    connect() {
        this.element.controllers ||= {}
        this.element.controllers[this.identifier] = this
    }
}
