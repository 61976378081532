import ApplicationController from "./application_controller";
import EditorJS, {OutputData} from "@editorjs/editorjs";

import Header from '@editorjs/header';
import RawTool from '@editorjs/raw';
import ImageTool from '@editorjs/image';
import List from "@editorjs/list";
import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import LinkAutocomplete from '@editorjs/link-autocomplete';


export default class EditorController extends ApplicationController {
    static targets = ['editor', 'editorField']
    declare editor: EditorJS
    declare editorTarget: HTMLElement
    declare editorFieldTarget: HTMLInputElement

    connect() {
        super.connect();
        this.editor = new EditorJS({
            holder: this.editorTarget,
            tools: {
                header: {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    class: Header,
                    shortcut: 'CMD+SHIFT+H',
                },
                link: {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    class: LinkAutocomplete,
                    config: {
                        endpoint: '/admin/editor/autocomplete',
                        queryParam: 'search'
                    }
                },
                list: {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    class: List,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    }
                },
                image: {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: '/admin/editor/images/new', // Your backend file uploader endpoint
                            byUrl: '/admin/editor/images/new', // Your endpoint that provides uploading by Url
                        }
                    }
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                embed: { class: Embed },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                quote: Quote,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                table: Table,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                raw: { class: RawTool },
            },
            placeholder: "Build rich content here.",
            onChange: () => { void this.save(); return; }
        })
        void this.setup()
    }

    async setup() {
        await this.editor.isReady
        await this.load()
    }

    async save() {
        const outputData = await this.editor.save()
        this.editorFieldTarget.value = JSON.stringify(outputData)
    }

    async load() {
        await this.editor.render(<OutputData> JSON.parse(this.editorFieldTarget.value))
    }

    disconnect() {
        this.editor.destroy()
        super.disconnect();
    }
}
