import ApplicationController from "./application_controller";

export default class CollapsibeController extends ApplicationController {
    static targets = ['collapsible', 'toggleButton']

    declare collapsibleTarget: HTMLElement
    declare toggleButtonTarget: HTMLElement

    toggle() {
        if (this.collapsibleTarget.classList.contains('is-hidden')) {
            this.open()
        } else {
            this.close()
        }
    }

    open() {
        this.toggleButtonTarget.classList.remove('fa-plus')
        this.toggleButtonTarget.classList.add('fa-close')
        this.collapsibleTarget.classList.remove('is-hidden')
    }

    close() {
        this.toggleButtonTarget.classList.remove('fa-close')
        this.toggleButtonTarget.classList.add('fa-plus')
        this.collapsibleTarget.classList.add('is-hidden')
    }
}
