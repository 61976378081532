import Uppy, {UppyFile} from "@uppy/core";
import DragDrop from "@uppy/drag-drop";
import Tus from "@uppy/tus";
import ProgressBar from "@uppy/progress-bar";
import ApplicationController from "./application_controller";

declare global {
    interface Window { uppy?: Uppy; }
}

export default class VideoUploadController extends ApplicationController {
    declare endpointValue: string
    declare progressBarTarget: HTMLProgressElement
    declare dropAreaTarget: HTMLElement

    static targets = ["progressBar", "dropArea"]

    static values = {
        endpoint: String
    }

    private uppy?: Uppy;
    connect() {
        super.connect();

        const arr: Array<string> = []; // Array to hold the keys
        for (let i = 0; i < localStorage.length; i++){
            if (localStorage.key(i).substring(0,5) == 'tus::') {
                arr.push(localStorage.key(i));
            }
        }

        for (let i = 0; i < arr.length; i++) {
            localStorage.removeItem(arr[i]);
        }

        this.uppy = new Uppy({ debug: true })
            .use(DragDrop, { target: this.dropAreaTarget })
            .use(Tus, { endpoint: this.endpointValue, chunkSize: 150 * 1024 * 1024 })

        this.uppy.on('progress', this.progressHandler)
        this.uppy.on('file-added', this.dropHandler)
        console.log("Uppy connected")
        window.uppy = this.uppy
    }

    disconnect() {
        super.disconnect();
        this.uppy.close()
        this.uppy = null
    }

    progressHandler = (progress: number) => {
        this.progressBarTarget.innerText = `${progress}%`
        this.progressBarTarget.value = progress
    }

    dropHandler = (event: UppyFile) => {
        this.uppy.upload()
    }
}
