import ApplicationController from "./application_controller";

export default class NavbarController extends ApplicationController {
  static targets = ['hamburger', 'navbarMenu']

  declare hamburgerTarget: HTMLElement
  declare navbarMenuTarget: HTMLElement

  toggle() {
    if (this.hamburgerTarget.classList.contains('is-active')) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.hamburgerTarget.classList.add('is-active')
    this.navbarMenuTarget.classList.add('is-active')
  }

  close(event?: MouseEvent) {
    if (event) {
      if (event.target === this.hamburgerTarget || this.hamburgerTarget.contains(event.target as HTMLElement)) return
    }
    this.hamburgerTarget.classList.remove('is-active')
    this.navbarMenuTarget.classList.remove('is-active')
  }
}
