import Swal from 'sweetalert2'
import ApplicationController from "./application_controller";

interface ConfirmationDetail {
    confirmed?: boolean;
}

export default class ConfirmableController extends ApplicationController {
    static values = {
        title: { type: String, default: 'Are you sure?' },
        text: { type: String, default: '' }
    }

    declare titleValue: string
    declare textValue: string

    async click(event: PointerEvent | CustomEvent) {
        if (!event.isTrusted) return

        event.preventDefault()
        event.stopPropagation()

        const result = await Swal.fire({
            title: this.titleValue,
            text: this.textValue,
            showCancelButton: true,
            showConfirmButton: true
        })

        if (!result.isConfirmed) return

        (this.element as HTMLElement).click()
    }
}
