// Import your application, and register all stimulus controllers here.
// Keep controllers in alphabetical order.

import { application } from "./application"

import ApplicationController from "./application_controller"
application.register("application", ApplicationController)

import CollapsibeController from "./collapsible_controller"
application.register("collapsible", CollapsibeController)

import ConfirmableController from "./confirmable_controller"
application.register("confirmable", ConfirmableController)

import DismissableController from "./dismissable_controller"
application.register("dismissable", DismissableController)

import EditorController from "./editor_controller"
application.register("editor", EditorController)

import NavbarController from "./navbar_controller"
application.register("navbar", NavbarController)

import VideoUploadController from "./video_upload_controller"
application.register("video-upload", VideoUploadController)
